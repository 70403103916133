exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appartments-js": () => import("./../../../src/pages/appartments.js" /* webpackChunkName: "component---src-pages-appartments-js" */),
  "component---src-pages-dinner-js": () => import("./../../../src/pages/dinner.js" /* webpackChunkName: "component---src-pages-dinner-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resto-js": () => import("./../../../src/pages/resto.js" /* webpackChunkName: "component---src-pages-resto-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

